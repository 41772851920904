<template>
  <CCard>
    <CCardBody>
      <h3>{{ operationName }} Currency</h3>
      <br />
      <CAlert v-if="message" :color="alertType"> {{ message }}</CAlert>
      <CRow>
        <CCol col="10"></CCol>
        <CCol class="text-right">
          <div><label>Active</label></div>
          <CSwitch
            color="success"
            size="lg"
            :checked.sync="currency.active"
          ></CSwitch>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <CInput label="Name" type="text" v-model="currency.name"></CInput>
        </CCol>

        <CCol sm="6">
          <CInput
            textarea="true"
            label="Currency Code"
            v-model="currency.currencyCode"
          ></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <CInput label="Currency Prefix" type="text" v-model="currency.currencyPrefix"></CInput>
        </CCol>

        <CCol sm="6">
          <CInput
            textarea="true"
            label="Currency Suffix"
            v-model="currency.currencySuffix"
          ></CInput>
        </CCol>
      </CRow>

      <CRow>
        <CCol col="9"></CCol>
        <CCol class="d-flex justify-content-end">
          <CButton
            color="secondary"
            @click="goBack"
            class="btn-lg"
            style="margin-right: 15px"
          >
            <CIcon name="cil-list" />
            Back to the list
          </CButton>

          <CButton color="primary" @click="save()" class="btn-lg">
            <CIcon name="cil-save" /> Save
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
export default {
  name: "EditCurrency",
  data: () => {
    return {
      operationName: "Create New",
      emptyGuid: "00000000-0000-0000-0000-000000000000",

      currency: {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
        currencyCode: null,
        currencySuffix: null,
        currencyPrefix: null,
        active: false,
      },

      // Alert
      alertType: "danger",
      message: null,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    save() {
      let self = this;
      axios
        .post(this.$apiAdress + "/v1/Currency", self.currency)
        .then((response) => {
          self.currency.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully updated currency.";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          console.log(error)
          self.message = error.response?.data?.message ?? error;
        });
    },
    getCurrency(id) {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Currency/" + id)
        .then(function (response) {
          self.currency = response.data;
          self.operationName = "Edit";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    let self = this;
    // Edit OR Create
    if (self.$route.params.id != this.emptyGuid) {
      self.getCurrency(self.$route.params.id);
    }
  },
};
</script>
